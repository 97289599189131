<template>
  <div>
    <!-- Title Bar For Section List-->
    <v-card flat>
      <v-toolbar dense flat color="secondary" class="mb-2" dark>
        <v-icon>fal fa-heartbeat</v-icon>
        <v-toolbar-title class="pl-2">Benefits</v-toolbar-title>
      </v-toolbar>
    </v-card>

    <!--Pagination-->
    <pagination
      :show="benefitsStore.benefits.data && benefitsStore.benefits.data.length > 0"
      :showTotal="true"
      :currentPage="benefitsStore.benefits.current_page"
      :lastPage="benefitsStore.benefits.last_page"
      :total="benefitsStore.benefits.data ? benefitsStore.benefits.data.length : 0"
      v-on:paginate-event="filter($event)"
    />

    <!--Edit Benefit-->
    <benefit-entry />

    <!-- Delete Dialog Box-->
    <v-dialog v-model="deleteDialog" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Delete Payment?</span>
          <v-spacer />
          <v-btn outlined rounded text @click.native="closeDeleteDialog()"
            ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row dense>
              <v-col cols="12" sm="12">
                {{ deleteMessage }} will be deleted from the system!
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn block rounded color="error" @click.native="removeBenefit(recordID)">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div v-if="progressStore.progress !== true">
      <!--Benefits List-->
      <!--Table-->
      <v-simple-table
        v-if="benefitsStore.benefits.data &&
          benefitsStore.benefits.data.length > 0
        "
        :fixed-header="true"
        class="my-2"
      >
        <template #default>
          <thead>
            <tr>
              <th class="text-center grey--text text--darken-1" style="width:120px">Benefit ID</th>
              <th class="text-center grey--text text--darken-1" style="width:150px">School Year</th>
              <th class="text-left grey--text text--darken-1" style="width:150px">Administrator</th>
              <th class="text-left grey--text text--darken-1" style="width:400px">Insurance Offered</th>
              <th class="text-left grey--text text--darken-1" style="width:400px">Paid Time Off (per year)</th>
              <th class="text-left grey--text text--darken-1" style="width:350px">Benefit Start Time</th>
              <th class="text-center grey--text text--darken-1">Can Add Dependents?</th>
              <th class="text-left grey--text text--darken-1" style="width:450px">Additional/ Other Benefits</th>
              <th style="width:10px"></th>
              <th v-if="usersStore.authUser.f_admin === 1" style="width:10px"></th>
            </tr>
          </thead>
          <tbody v-for="(item) in benefitsStore.benefits.data" :key="item.id">
            <tr class="clickable">
              <!--1 - Benefit ID-->
              <td
                @click="openEntry(item.id, 0)"
                class="text-center tw-align-top light-blue--text text--darken-4"
              >
                {{ item.id }}
              </td>
              <!--2 - School Year-->
              <td
                @click="openEntry(item.id, 0)"
                class="text-center tw-align-top light-blue--text text--darken-4"
              >
                {{ item.d_schoolYear }}
              </td>
              <!--3 - Administrator-->
              <td
                @click="openEntry(item.id, 0)"
                class="text-left tw-align-top light-blue--text text--darken-4"
              >
                {{ item.d_admin }}<br />
                {{ item.d_position }}
              </td>
              <!--4 - Insurance Offered-->
              <td
                @click="openEntry(item.id, 0)"
                class="text-left tw-align-top light-blue--text text--darken-4"
              >
                <span v-for="(item, index) in item.j_insurance" :key="index">
                  &bull; {{ item.d_type }}: {{ item.d_value }}<br />
                </span>
              </td>
              <!--5 - Paid Time Off-->
              <td
                @click="openEntry(item.id, 0)"
                class="text-left tw-align-top light-blue--text text--darken-4"
              >
                <span v-for="(item, index) in item.j_paidTimeOff" :key="index">
                  &bull; {{ item.d_type }}: {{ item.d_value }}
                  {{ item.d_value !== 'Not Offered' ? ' days' : ''}}<br />
                </span>
              </td>
              <!--6 - Benefits Effective Time-->
              <td
                @click="openEntry(item.id, 0)"
                class="text-left tw-align-top light-blue--text text--darken-4"
              >
                <span v-for="(item, index) in item.j_effectiveInterval" :key="index">
                  &bull; {{ item.d_type }}: {{ 'At ' + item.d_value }}<br />
                </span>
              </td>
              <!--7 - Can Add Dependents-->
              <td
                @click="openEntry(item.id, 0)"
                class="text-left text-center tw-align-top light-blue--text text--darken-4"
              >
                {{ $_yesNo(item.f_addDependents) }}
              </td>
              <!--8 - Additional Benefits-->
              <td
                @click="openEntry(item.id, 0)"
                class="text-left tw-align-top light-blue--text text--darken-4"
              >
                <span v-for="(item) in item.j_benefitsAdditional" :key="item">
                  &bull; {{ item }}<br />
                </span>

                <span v-for="(item, index) in item.j_benefitsOther" :key="index">
                  &bull; {{ item.d_value }}<br />
                </span>
              </td>
              <!--11 - Duplicate Benefit Record-->
              <td style="text-align: center">
                <v-tooltip
                  left
                  color="primary"
                >
                  <template #activator="{ on }">
                    <v-btn icon v-on="on" @click="openEntry(item.id, 1)">
                      <v-icon small color="primary">
                        fal fa-clone
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Duplicate Record</span>
                </v-tooltip>
              </td>
              <!--12 - Delete Benefit Record-->
              <td v-if="usersStore.authUser.f_admin === 1">
                <v-tooltip
                  left
                  color="error"
                >
                  <template #activator="{ on }">
                    <v-btn
                      icon
                      v-on="on"
                      @click="
                        openDeleteDialog(
                          item.id,
                          'The benefit record for the ' +
                            item.d_schoolYear + ' school year '
                        )
                      "
                    >
                      <v-icon small color="error">
                        fal fa-trash
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Remove Record</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-list v-else class="py-2">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="error--text text-subtitle-1 text-xs-center"
              >No Benefits Exist </v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import { uploadHeader } from "@/env";
import TitleBar from "@/components/TitleBar";
import Pagination from "@/components/Pagination";
import BenefitEntry from '@/components/BenefitEntry';
import TextDisplay from '@/components/TextDisplay';

const { mapFields } = createHelpers({
  getterType: "benefitsStore/getFilterField",
  mutationType: "benefitsStore/updateFilterField"
});

export default {
  name: "Benefits",
  components: {
    TitleBar,
    Pagination,
    BenefitEntry,
    TextDisplay
  },
  mixins: [mixin],
  computed: {
    ...mapState({
      benefitsStore: state => state.benefitsStore,
      progressStore: state => state.progressStore,
      valueListsStore: state => state.valueListsStore,
      usersStore: state => state.usersStore
    }),
    ...mapFields([
    ])
  },
  props: {
    /* All List = 1 */
    benefitSection: {
      type: Number,
      required: false
    }
  },
  created() {
    this.initialize();
  },
  data() {
    return {
      filterMenu: false,
      deleteDialog: false,
      deleteMessage: "",
      recordID: ""
    };
  },

  methods: {
    async initialize() {
      /* Grab provider detail using uuid passed as url parameter */
      const provider = this.$store.getters["providersStore/getProvider"];
      this.fk_providerID = provider.id;

      /* Pull provider benefits list */
      const filters = {
        fk_providerID: provider.id
      };
      await this.$store.dispatch("benefitsStore/fetch", filters);
      this.$store.dispatch("progressStore/set", false);
    },

    async filter(page) {
      this.filterMenu = false;
      this.page = page;
      this.initialize();
    },

    async openEntry(id, duplicateFlag) {
      await this.loadValueLists();
      if (duplicateFlag === 1) {
        await this.$store.dispatch("benefitsStore/duplicate", id);
      } else {
        await this.$store.dispatch("benefitsStore/entry", id);
      }
    },

    async loadValueLists() {
      const vldata = {
        id: [38]
      };
      await this.$store.dispatch("valueListsStore/items", vldata);
      await this.$store.dispatch("yearsStore/valueList");
    },

    openDeleteDialog(id, message) {
      this.deleteMessage = message;
      this.recordID = id;
      this.deleteDialog = true;
    },

    closeDeleteDialog() {
      this.deleteDialog = false;
      this.recordID = "";
      this.deleteMessage = "";
    },

    async removeBenefit(id) {
      this.closeDeleteDialog();
      const data = {
        id: id
      };
      const response = await this.$store.dispatch("benefitsStore/delete", data);

      let message;
      if (response && response.status === 200) {
        message = {
          color: "success",
          text: "Record has been deleted."
        };
      } else {
        message = {
          color: "error",
          text: "There is an issue with deleting the record at this time."
        };
      }
      this.$store.commit("messagesStore/setMessage", message);
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
